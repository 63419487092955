import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
// import { Elements } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";
import ReactGA from "react-ga4";
import loadable from "@loadable/component";

// //Components - Old
// import Home from "./Pages/Home";
// import Login from "./Pages/Home/Login";
// import Register from "./Pages/Home/SignUp";
// import ForgotPass from "./Pages/Home/ForgotPassword";
// import Cover from "./Pages/Cover";
// import FirstPage from "./Pages/FirstPage";
// import Layout from "./Pages/Layout";
// import Dates from "./Pages/DatesPage";
// import Review from "./Pages/Review";
// import Cart from "./Pages/Cart";
// import Checkout from "./Pages/Checkout";
// import PaymentForm from "./Pages/PaymentForm";
// import OrderSuccess from "./Pages/OrderSuccess";
// import PaymentFailed from "./Pages/PaymentFailed";

// import MyAccount from "./Pages/MyAccount/AccountInfo";
// import MyOrders from "./Pages/MyAccount/MyOrders";
// import EditProfile from "./Pages/MyAccount/EditProfile";
// import ManageAddress from "./Pages/MyAccount/ManageAddress";

// import AddIns from "./Pages/Addins";
// import Referral from "./Pages/Referral";
// import ShareStory from "./Pages/ShareStory";
// import Store from "./Pages/Store";
// import GiftCard from "./Pages/GiftCard";
// import ContactUs from "./Pages/ContactUs";
// import PageContent from "./Pages/PageContent";

import config from "./config";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// const stripePromise = loadStripe(config.STRIPE_PUBLISHABLE_KEY);

// Components-Lazy
const Home = loadable(() => import("./Pages/Home"));
const Login = loadable(() => import("./Pages/Home/Login"));
const Register = loadable(() => import("./Pages/Home/SignUp"));
const ForgotPass = loadable(() => import("./Pages/Home/ForgotPassword"));
const Cover = loadable(() => import("./Pages/Cover"));
const FirstPage = loadable(() => import("./Pages/FirstPage"));
const Layout = loadable(() => import("./Pages/Layout"));
const Dates = loadable(() => import("./Pages/DatesPage"));
const Review = loadable(() => import("./Pages/Review"));
const Cart = loadable(() => import("./Pages/Cart"));
const OrderSuccess = loadable(() => import("./Pages/OrderSuccess"));
const PaymentFailed = loadable(() => import("./Pages/PaymentFailed"));
const MyAccount = loadable(() => import("./Pages/MyAccount/AccountInfo"));
const MyOrders = loadable(() => import("./Pages/MyAccount/MyOrders"));
const EditProfile = loadable(() => import("./Pages/MyAccount/EditProfile"));
const ManageAddress = loadable(() => import("./Pages/MyAccount/ManageAddress"));
const AddIns = loadable(() => import("./Pages/Addins"));
const ShareStory = loadable(() => import("./Pages/ShareStory"));
const Store = loadable(() => import("./Pages/Store"));
const GiftCard = loadable(() => import("./Pages/GiftCard"));
const ContactUs = loadable(() => import("./Pages/ContactUs"));
const PageContent = loadable(() => import("./Pages/PageContent"));


export default function App() {
  ReactGA.initialize("G-JQ0HP4B7Y5");
  // const options = {
  //   // passing the client secret obtained from the server
  //   clientSecret: config.STRIPE_SECRET_KEY,
  // };

  const [, setMyUser] = useState("");
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/login"
          element={
            <GoogleOAuthProvider clientId={`${config.GOOGLE_CLIENTID}`}>
              <Login setMyUser={setMyUser} />
            </GoogleOAuthProvider>
          }
        />

        <Route
          path="/register"
          element={
            <GoogleOAuthProvider clientId={`${config.GOOGLE_CLIENTID}`}>
              <Register setMyUser={setMyUser} showBox={"register"} />
            </GoogleOAuthProvider>
          }
        />

        <Route
          path="/verify/:token"
          element={
            <GoogleOAuthProvider clientId={`${config.GOOGLE_CLIENTID}`}>
              <Register showBox={"emailverify"} />
            </GoogleOAuthProvider>
          }
        />
        <Route
          path="/forgot-password"
          element={<ForgotPass showBox={true} />}
        />
        <Route
          path="/change-password/:token"
          element={<ForgotPass showBox={false} />}
        />
        <Route path="/cover" element={<Cover />} />
        <Route path="/firstpage" element={<FirstPage />} />
        <Route path="/layout" element={<Layout />} />
        <Route path="/addins" element={<AddIns />} />
        <Route path="/dates" element={<Dates />} />
        <Route path="/review" element={<Review />} />
        <Route path="/cart" element={<Cart />} />
        {/* <Route path="/checkout" element={<Checkout />} /> */}
        {/* <Route
          path="/payment"
          element={
            <Elements stripe={stripePromise}>
              <PaymentForm />
            </Elements>
          }
        /> */}
        <Route path="/order-success" element={<OrderSuccess />} />
        <Route path="/payment-failed" element={<PaymentFailed />} />
        <Route path="/myaccount" element={<MyAccount />} />
        <Route path="/myorders" element={<MyOrders />} />
        <Route path="/edit-profile" element={<EditProfile />} />
        <Route path="/change-password" element={<EditProfile />} />
        <Route path="/addressbook" element={<ManageAddress />} />

        {/* <Route path="/referral" element={<Referral />} /> */}
        <Route path="/shareStory" element={<ShareStory />} />
        <Route path="/store" element={<Store />} />
        <Route path="/giftCard" element={<GiftCard />} />
        <Route path="/about-us" element={<PageContent slug="about-us" />} />
        <Route path="/faqs" element={<PageContent slug="faqs" />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route
          path="/shipping-return"
          element={<PageContent slug="shipping-return" />}
        />
        <Route
          path="/privacy-policy"
          element={<PageContent slug="privacy-policy" />}
        />
        <Route
          path="/terms-conditions"
          element={<PageContent slug="terms-services" />}
        />
      </Routes>
    </>
  );
}
